const altByIcon = {
  'advanced': 'Advanced icon',
  'back_button': 'Back button icon',
  'back': 'Back icon',
  'bin': 'Bin icon',
  'bin--white': 'Bin icon',
  'caret-left': 'Caret left icon',
  'caret-right': 'Caret tight icon',
  'chevron': 'Chevron icon',
  'clock': 'Clock icon',
  'close_modalunidades': 'Close icon',
  'close': 'Close icon',
  'credits': 'Credits icon',
  'dots': 'Dots icon',
  'download': 'Download icon',
  'download--white': 'Download icon',
  'edit': 'Edit icon',
  'info': 'Information icon',
  'loader': 'Loader icon',
  'loader-white': 'Loader icon',
  'menu' : 'Menu icon',
  'moreoptions': 'More Options icon',
  'no-prompts': 'No prompts icon',
  'next_button': 'Next Button icon',
  'password1': 'Password icon',
  'password2': 'Password icon',
  'plus-white': 'Plus sign icon',
  'plus-neon': 'Plus sign icon',
  'plus-bold': 'Plus sign icon',
  'remove': 'Remove icon',
  'signout': 'Sing out icon',
  'tick': 'Tick icon',
  'x': 'X sign icon',

}

export type TypeAppIcon = keyof typeof altByIcon
export const iconByName = (iconName: TypeAppIcon) => ({
  type: 'svg' as const,
  src: `/images/svg/i--${iconName}.svg`,
  alt: altByIcon[iconName],
  size: { width: 24, height: 24 }
})
