'use client'
import type { FC, Dispatch, SetStateAction } from 'react'
import { motion, type Variants } from 'framer-motion'
import {useState, useCallback } from 'react'
import type { UseFormRegister, RegisterOptions } from 'react-hook-form'
import { type TypeFormInputs, Button, ButtonBack } from '.'
import { useTranslator } from '@/hooks'
import { font40_40, font15_20, mq, vw, hex2Rgba, parsePlaceHolder,getP18 } from '../styles'
import styled, { css } from 'styled-components'

type Props = {
  register: UseFormRegister<TypeFormInputs>
  step: number,
  setStep: Dispatch<SetStateAction<number>>
  setFormVisible: Dispatch<SetStateAction<boolean>>
  formVisible: boolean, 

}

const requireProps: RegisterOptions = {
  required: 'El email introducido no es válido',
  // pattern: /^ [-!# - '*+\/-9=?^-~]+(?:\.[-!#-' * +\/-9=?^-~]+)*@[-!#-'*+\/-9=?^-~]+(?:\.[-!#-'*+\/-9=?^-~]+)+$/i
}

export const InputName: FC<Props> = ({ register, step, setStep, setFormVisible , formVisible}) => {
  const registerKeys = register('title', requireProps)
  const { t } = useTranslator()
  const [nextStepDisabled, setNextStepDisabled] = useState(true)

  const onChange = useCallback(e => {
    setNextStepDisabled(e.target.value === undefined || e.target.value === '')
  }, [])

  return (
    <Fieldset key={1} variants={variants} initial='initial' animate={step === 1 ? 'animate' : step < 1 ? 'initial' : 'exit'} exit='exit' $step={step} $formVisible={formVisible}>
      <h1>{t('modal.new_project.steps.one.title')}</h1>
      <span>{t('modal.new_project.steps.one.instructions')}</span>
      <Input type='text' name='Nombre del proyecto' {...registerKeys} id='title' placeholder={t('modal.new_project.steps.one.placeholder') as string} onChange={(e) => { onChange(e)}}/>
      <div className='actions'>
        <ButtonBack onClick={() => { setFormVisible(false) }} text={t('commons.actions.prev') as string}  iconName='caret-left' />
        <Button disabled={nextStepDisabled} onClick={() => { setStep(2) }} text={t('commons.actions.next') as string}  iconName='caret-right' afterString={true} />
      </div>
    </Fieldset>
  )
}

const Fieldset = styled(motion.fieldset)<{ $step: number, $formVisible: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 360px;
  pointer-events: ${({ $formVisible, $step }) => ($formVisible && $step === 1) ? 'auto' : 'none' };
  transform: translateX(${({ $step }) => $step === 1 ? 0 : 'none'});
  transition: 300ms transform linear;  width: 100%;
  width: 100%;

  ${({ $formVisible, $step }) => (!$formVisible || $step !== 1) && css`
    div, ul, ol {
      pointer-events: none !important;
    }
  `}

  ${mq.greaterThan('tablet')} {
    max-width: 480px;
  }

  span, h1 {
    color: ${({ theme }) => theme.colors.neon_purple};
    margin-bottom: 35px;
    text-align: center;
  }

  span {
    ${font15_20(true, 400)}
  }

  h1 {
    ${font40_40(true, 400)}
  }

  .actions {
    display: flex;
    margin: 100px 0 20px;
    justify-content: space-around;

    button {
      height:52px;
      margin: 0 2px;
      transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);

      &:last-of-type {
        svg {
          margin-left: 10px;
        }

        &:hover {
          path {
            stroke: #FFF;
          }
        }
      }
    }
  }
`

const variants: Variants = {
  initial: {
    opacity: 0,
    x: 0,
    zIndex:0,
    pointerEvents: 'none',
    transition: {
      duration: 0
    }
  },
  animate: {
    opacity: 1,
    x: 0,
    pointerEvents: 'auto',
    zIndex:999,
    transition: {
      duration: .8,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    x: 0,
    zIndex:0,
    pointerEvents: 'none',
    transition: {
      duration: .3,
      ease: 'easeOut'
    }
  }
}


const Input = styled.input`
 ${getP18()}
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid;
  border-color: ${({ theme }) => hex2Rgba(theme.colors.text, .4)};
  color: inherit;
  height: ${vw(60, 'mobile')};
  padding: ${vw(25, 'mobile')} 0 ${vw(12, 'mobile')};
  transition: 300ms border-color ease-out;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    height: ${vw(60, 'nexus7')};
    padding: ${vw(25, 'nexus7')} 0 ${vw(12, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(60, 'desktop')};
    padding: ${vw(25, 'desktop')} 0 ${vw(12, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    height: 60px;
    padding: 25px 0 12px;
  }

  &[readonly],
  &[disabled] {
    cursor: auto;
    pointer-events: none;
  }

  &[readonly] {
    opacity: .3;

    + span {
      opacity: .3;
    }

  }

  &[disabled] {
    opacity: .2;

    + span {
      opacity: .2;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 500px ${({ theme}) => theme.colors.background} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
    box-shadow: 0 0 0 100px ${({ theme}) => theme.colors.background} inset;
    color: inherit;
  }

  ${({ theme }) => parsePlaceHolder(`
    opacity: .4;
    color: ${theme.colors.text};
  `)}
  
  &:focus {
    border-color: ${({ theme }) =>  theme.colors.text};
  }
  
`