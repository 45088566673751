export const LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const HOSTNAME = process.env.NEXT_PUBLIC_PROJECT_HOSTNAME
export const DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
export const STAGING = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
export const PRO = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export * from './shuffle'
export * from './string'
export * from './icons'
export * from './cookie'
export * from './inputAutoComplete'
export * from './inputPatterns'
export * from './aicam'
export * from './enum'
export * from './jsonld'
