// const NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE
// const localeCountry = getLocaleCountry(NEXT_PUBLIC_LOCALE)
// const LOCALE = localeCountry.split('-')[0]
// const REGION = localeCountry.split('-')[1]
// const DY_API_URL = `${process.env.DY_API}/${REGION}/${LOCALE}`
const DY_API_URL = `${process.env.DY_API}`
const DY_CMS = `${process.env.DY_CMS}`


export const endpoints = {
  'auth-login': () => `${DY_API_URL}/test`, // TEST endpoint ONLY
  'auth-signup': () => `${DY_API_URL}/test`, // TEST endpoint ONLY
  'auth-reset-password': (query) => `${DY_API_URL}/test/${query.user_id}`, // TEST endpoint ONLY
  'create-tune': () => `${DY_API_URL}/tunes`,
  'update-tune': (query) => `${DY_API_URL}/tunes/${query.project_id}`,
  'get-tune-images': (query) => `${DY_API_URL}/tunes/${query.slug}`,
  'get-latest-images': () => `${DY_API_URL}/tunes/`,
  'delete-image': (query) => `${DY_API_URL}/tunes/${query.project_id}/media/${query.image_id}/delete`,
  'confirm-astria': (query) => `${DY_API_URL}/tunes/${query.project_id}/confirm`,
  'download-image': (query) => `${DY_API_URL}/prompt_images/${query.promt_image_id}/download`,
  /**
   * This endpoint will create a `Stripe` checkout session in our Rails backend
  */
  'create-order': () => `${DY_API_URL}/orders`,
  'graphql': () => `https://${DY_CMS}/graphql`,
}
