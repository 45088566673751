import { createContext, useState, useEffect } from 'react'
import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'

type TypeState = {
  time: number
  setTime: Dispatch<SetStateAction<number>>
  createdAt: Date
  setCreatedAt: Dispatch<SetStateAction<Date>>
}

const initialState: TypeState = {
  time: 1,
  setTime: () => { },
  createdAt: null,
  setCreatedAt: () => { },
}
type Props = {
  children: ReactNode
}

export const TimerContext = createContext<TypeState | null>(initialState)

export const TimerProvider: FC<Props> = ({ children }) => {
  const [createdAt, setCreatedAt] = useState(null)
  const [time, setTime] = useState(Math.floor(Date.now()) / 1000) // Time in seconds
  
  useEffect(() => {
    if(createdAt) {
      const dateCreatedAt = new Date(createdAt)
      const end = dateCreatedAt.getTime()+ 21 * 60 * 1000
      const endTime = new Date(end)

      setTime(Math.floor((endTime.getTime() - Date.now()) / 1000))
    }
  }, [createdAt])
  
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime > 0 ? prevTime - 1 : 0)
    }, 1000)

    return () => clearInterval(timer)
  }, [])
  return (
    <TimerContext.Provider value={{time, setTime,  createdAt, setCreatedAt}}>
      {children}
    </TimerContext.Provider>
  )
}