'use client'
import { type FC } from 'react'
import styled from 'styled-components'
import { Figure } from './Figure'
import { iconByName, type TypeAppIcon } from '@/utils'

type Props = {
  text: string,
  iconName?: TypeAppIcon
  onClick?: () => void
  type?: 'button' | 'submit',
  disabled?: boolean
}

export const ButtonBack: FC<Props> = ({ type = 'button', onClick = () => {}, text, iconName, disabled = false }) => {
  return (
    <Btn type={type} onClick={onClick} disabled={disabled}>
      {iconName && <Figure media={iconByName(iconName)} />}
      {text}
    </Btn>
  )
}

const Btn = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 36px;
  color: var(--color-white);
  display: flex;
  font-size: 18px;
  height: 65px;
  justify-content: center;
  line-height: 24px;
  padding: 16px 40px;
  width: auto;
  width: fit-content;

  &:disabled {
    opacity: .6;
    pointer-events: none;
    user-select: none;
  }

  figure {
    display: flex;
    height: 15px;
    margin-right: 20px;
    width: 15px;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
`
