'use client'
import type { FC, Dispatch, SetStateAction, Ref } from 'react'
import { memo, useMemo, useState, useEffect, useCallback } from 'react'
import type { UseFormRegister as TypeRegister } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion, type Variants } from 'framer-motion'
import { Button, ButtonBack, Figure } from './'
import { iconByName, cookie, getDynamicText } from '@/utils'
import { mq, vw } from '@/styles'
import { useTranslator, useSession } from '@/hooks'
import { QUERY_TUNE_PREVIEW_IMAGES, fetchAPI } from '@/api'
import { useRouter, useParams } from 'next/navigation'

type Props = {
  step: number
  setStep: Dispatch<SetStateAction<number>>
  setSelfiesFiles: Dispatch<SetStateAction<File[]>>
  onChange?: () => void
  readonly register?: TypeRegister<any>
  processingIdTune: number
  previewImagesState: boolean
  setPreviewImagesState: Dispatch<SetStateAction<boolean>>
  previewSelfies: any
  setPreviewSelfies: Dispatch<SetStateAction<any>>
  setAlreadyUploaded: Dispatch<SetStateAction<boolean>>
  alreadyUploaded: boolean
  processingImages : number
  setProcessingImages: Dispatch<SetStateAction<number>>
  processingMessageRef: Ref<HTMLDivElement>
  radioValue: string
}

const getInstructions = (instructions) => instructions.map((item, idx) => {
  const { em, text } = item
  return (
    <li key={idx}>
      <Figure media={iconByName('tick')} />
      <p className='tag'>{em} <span>{text}</span></p>
    </li>
  )
})

const checkIfImageIsAvailable = images => {
  let available = []
  let processing = []
  images.map((item) => {
    if (item.status === 'finished') {
      available.push(item)
    } else {
      processing.push(item)
    }
  })

  const results = { available, processing }
  return results
}

const getPreviewSelfies = (previewSelfies, onClickRemoveImage) => previewSelfies.map((item, idx) => {
  return (
    <li key={`${idx}-img`}>
      <Figure className={'preview'} media={item.image} fill={false} />
      <button key={`${idx}-btn`} onClick={(e) => onClickRemoveImage(e, item)}><Figure media={iconByName('remove')} /></button>
    </li>
  )
})

const getProcessingPreviewSelfies = (processing, onClickRemoveImage) => {
  const arr = Array.from({length: processing})
  return arr.map((_item, idx) => {
    let media = {
      type: 'svg' as const,
      src: '/images/svg/logo--aicam-studio.svg',
      alt: 'AiCam ',
      size: { width: 10, height: 10 }
    }
    return (
      <li key={`${idx}-img`}>
        <Figure className={'preview placeholder'} media={media} fill={false} />
        <button key={`${idx}-btn`} onClick={(e) => onClickRemoveImage(e, _item)}><Figure media={iconByName('remove')} /></button>
      </li>
    )
  })

}

export const InputFile: FC<Props> = memo(({ setStep, step, setSelfiesFiles, onChange, processingIdTune, previewImagesState, setPreviewImagesState, previewSelfies, setPreviewSelfies, setAlreadyUploaded, alreadyUploaded, processingImages, setProcessingImages, radioValue }) => {
  const [idxPreviewSelfies, setIdxPreviewSelfies] = useState<number>(0)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const { t } = useTranslator()
  const { getUser, setLoaderActive } = useSession()
  const { push } = useRouter()
  const { lang } = useParams()

  const removeSelfiesById = useCallback(async (id) => {
    const token = cookie.get('DY_AICAM_USER')
    try {
      const res = await fetchAPI('delete-image', { project_id: processingIdTune, image_id: id }, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'DY-User-Token': token
        },
      })

      if (!res.ok) throw new Error('DELETE IMAGE FROM TUNE FAILED')
      if (res.status === 200) {
        const data = await res.json()
        console.log('DELETE IMAGE - data', data)
      }
    } catch (e) {
      console.warn('DELETE IMAGE - Catch e', e)
    }
  }, [processingIdTune])

  const handleOnChange = useCallback(e => {
    let sum = 0
    let i = 0
    let displayAlert = false
    let maximumFilesAlert = false
    console.log('e', e)
    if (e.target.files) {
      const fileArray = Array.from(e.target.files) as File[]
      const filesAccepted = []
      for(let obj of fileArray) {
        if (obj.type === 'image/jpeg') {
          const sizeMb = obj.size / (1024*1024)
          sum += sizeMb
          i++
          if(sizeMb <= 15) {
            filesAccepted.push(obj)
            if (previewSelfies.length + i > 50) {
              maximumFilesAlert = true
            }
          }
          else displayAlert = true
        } else displayAlert = true
      }
      setSelfiesFiles(filesAccepted)
      e.target.value = null
      console.log('e.target.files', e.target.files)

      if (displayAlert) {
        alert(t('modal.new_project.steps.three.error') as string)
        displayAlert = false
      } else if(maximumFilesAlert) {
        alert(t('modal.new_project.steps.three.error_max_file') as string)
        maximumFilesAlert = false
      }
      onChange && onChange()
    }
  }, [onChange, previewSelfies, setSelfiesFiles, t])

  const onClickRemoveImage = useCallback((e,fileToRemove) => {
    e.preventDefault()
    if (confirm(t('modal.new_project.steps.three.action.remove_image') as string)) {
      setPreviewSelfies((prevData) => {
        return prevData.filter(file => file !== fileToRemove)
      })
      removeSelfiesById(fileToRemove.id)

    } else {
      // Do nothing!
      return
    }
  }, [removeSelfiesById, setPreviewSelfies, t])

  const onClickConfirmAstria = useCallback(async() => {
    setLoaderActive(true)
    setDisabledButton(true)
    const token = cookie.get('DY_AICAM_USER')
    try {
      const res = await fetchAPI('confirm-astria', { project_id: processingIdTune}, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'DY-User-Token': token
        },
      })

      if (!res.ok) throw new Error('CONFIRM ASTRIA TUNE FAILED')
      if (res.status === 200) {
        // const data = await res.json()
        getUser(token)
        push(`/${lang}/project/${processingIdTune}`)
      }
    } catch (e) {
      setLoaderActive(false)
      console.warn('CONFIRM ASTRIA TUNE - Catch e', e)
    }
  }, [getUser, lang, processingIdTune, push, setLoaderActive])

  useEffect(() => {
    if(processingIdTune && alreadyUploaded ){
      let intervalId: NodeJS.Timeout
      const fetchPTuneData = async () => {
        const token = cookie.get('DY_AICAM_USER')
        try {
          const res = await fetchAPI('graphql', null, {
            method: 'POST',
            body: JSON.stringify({ query: QUERY_TUNE_PREVIEW_IMAGES(processingIdTune) }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'DY-User-Token': token
            },
          }) as Response
          if (!res.ok) throw new Error('QUERY_TUNE_PREVIEW_IMAGES failed')
          if (res.status !== 200) return
          const data = await res.json()
          const tuneData = data.data.tune
          setPreviewSelfies(tuneData.media)
          const checkImages = checkIfImageIsAvailable(tuneData.media)
          const { available, processing } = checkImages
          setIdxPreviewSelfies(available.length)
          setProcessingImages(processing.length)

          if(processing.length === 0) {
            clearInterval(intervalId)
            setPreviewImagesState(false)
            setAlreadyUploaded(false)
          }

        } catch (error) {
          console.error('catch QUERY_TUNE_PREVIEW_IMAGES error', error)
        }
      }

      if (processingImages !== 0) {
        intervalId = setInterval(fetchPTuneData, 1000)
      }

      return () => clearInterval(intervalId)
    }
  }, [alreadyUploaded, idxPreviewSelfies, previewImagesState, previewSelfies, processingIdTune, processingImages, setAlreadyUploaded, setPreviewImagesState, setPreviewSelfies, setProcessingImages])

  const finishedPreviewSelfies = useMemo(() => previewSelfies.filter(item => item.status === 'finished'), [previewSelfies])
  const PreviewSelfies = useMemo(() => getPreviewSelfies(finishedPreviewSelfies, onClickRemoveImage), [onClickRemoveImage, finishedPreviewSelfies])
  const ProcessingPreviewSelfies = useMemo(() => getProcessingPreviewSelfies(processingImages, onClickRemoveImage), [processingImages, onClickRemoveImage])
  const instructionsText = useMemo(() => radioValue === 'cat' || radioValue === 'dog' ? t('modal.new_project.steps.three.instructions.values_pets') : t('modal.new_project.steps.three.instructions.values'),[radioValue, t])

  const Instructions = useMemo(() => getInstructions(instructionsText), [instructionsText])

  return (
    <Fieldset key={3} variants={variants} initial='initial' animate={step === 3 ? 'animate' : step < 3 ? 'initial' : 'exit'} exit='exit' $step={step} >
      <legend>{t('modal.new_project.steps.three.title')}</legend>
      <p className='important'><span className='warning'>{t('modal.new_project.steps.three.instructions.important')}</span> {t('modal.new_project.steps.three.instructions.title')} </p>
      <ul>
        {Instructions}
      </ul>
      <input type='file' id='selfies' onChange={handleOnChange} multiple accept='image/jpeg' />
      <div id='processing'>
        <Div variants={variantsVertical} initial='initial' animate={previewSelfies.length > 0  && previewImagesState ? 'animate' : 'initial'} exit='exit'>
          <Figure media={iconByName('loader')} />
          <span>{t('modal.new_project.steps.three.action.processing')} ({idxPreviewSelfies}/{previewSelfies.length})</span>
        </Div>
        <Div variants={variantsVertical} initial='initial' animate={ (previewImagesState || previewSelfies.length === 0) ? 'initial' : 'animate'} exit='exit'>
          <span>{getDynamicText(t('modal.new_project.steps.three.action.amount_images') as string, {
            amountImages:previewSelfies.length
          })}
          </span>
        </Div>
      </div>
      <AnimatePresence mode='wait'>
        {previewSelfies.length > 0 ?
          <>
            <ol>
              {PreviewSelfies}
              {ProcessingPreviewSelfies}
              {(!previewImagesState && previewSelfies.length < 50)&&
               <li>
                 <Label htmlFor='selfies'>
                   <Figure media={iconByName('plus-white')} />
                 </Label>
               </li>
              }
            </ol>
            {!previewImagesState &&
              <div className='actions'>
                <ButtonBack onClick={() => { setStep(2) } } text={t('modal.new_project.steps.three.action.previous') as string} iconName='caret-left' />
                <Button onClick={onClickConfirmAstria} text={disabledButton ? t('modal.new_project.steps.three.action.sending') as string : t('modal.new_project.steps.three.action.send') as string} disabled={disabledButton} />
              </div>
            }
          </>
          :
          <motion.div variants={variantsVertical} initial='initial' animate='animate' exit='exit'>
            <Label htmlFor='selfies'>
              <Figure media={iconByName('plus-bold')} />
              {t('modal.new_project.steps.three.action.upload')}
            </Label>
          </motion.div>
        }
      </AnimatePresence>
    </Fieldset>
  )
})

const Fieldset = styled(motion.fieldset) <{ $step: number }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  pointer-events: ${({ $step }) =>  $step === 3 ? 'auto' : 'none' };
  width: 90%;

  ${mq.greaterThan('nexus7')} {
    max-width: 400px;
  }
  .tag {
    font-weight: 700;
    >span {
      font-weight: 300;
    }
  }

  ${mq.greaterThan('tablet')} {
    max-width: 700px;
  }

  ${({$step }) => ($step !== 3) && css`
    div, ul, ol {
      pointer-events: none !important;
    }
  `}

  li {
    display: flex;
    opacity: 1;
    transition: opacity 0 200ms ease;
    transition: display 200ms 200ms ease;

    &.remove {
      display: none;
      opacity: 0;
    }
  }

  legend {
    margin-bottom: 35px;
    text-align: center;
  }
  .important {
    max-width: 375px;
    padding-bottom: 15px;
    text-align:left;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      max-width: 550px;
    }
  }
  > button {
    margin-top: 35px;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    margin: 35px auto 20px;
    padding: 30px;

    button {
      height: 57px;
      margin: 0 2px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 375px;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      max-width: 550px;
    }

    li {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      margin: 0 auto;
      padding-bottom: 15px;
      width: 100%;

      figure {
        flex-shrink: 0;
        height: 8px;
        margin-right: 10px;
        margin-top: 7px;
        width: 8px;

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }


  p, .warning {
    color: var(--color-neon-purple);
    font-size: 15px;
    line-height: 20px;
  }

  input {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    user-select: none;
  }

  > div {
    overflow: hidden;
    max-width: 100vw;

  }

  #processing {
    display: grid;
    scroll-margin-top: 85px;
    scroll-margin: 85px;

    &:before {
      content: '';
      display: block;
      height:      80px;
      margin-top: -80px;
      user-select: none;
      visibility: hidden;
    }
  }

  .warning {
    font-weight: 700;
    margin-bottom:25px;
  }

  ol {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: calc(100vw - 16px);
    min-width: 90vw;
    overflow-y: auto;

    ${mq.greaterThan('nexus7')} {
      max-width: 375px;
      min-width: 375px;
    }

    li {
      max-width: 122px;
      overflow: hidden;
      padding: 5px;
      position: relative;
      width: auto;
      width: 32.5%;
      min-width: 32%;

      &:last-of-type {
        margin-right: auto;

        label {
          background-color: var(--color-neon-purple);
          border: 2px solid var(--color-neon-purple);
          margin: 0;
          transition: 200ms border-color linear;

          figure {
            height: 40px;
            width: 40px;
            transition: 200ms transform linear;
          }

          &:hover {
            border-color: var(--color-white);

            figure {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    label,
    img,
    .preview {
      border-radius: 5px;
      height: 122px;
      max-width: 100%;
      width: 100%;
    }

    .preview {
      + button {

        img {
          user-select: none;
        }
      }
    }

    .preview,
    .placeholder {
      min-width:30%;
    }

    .placeholder {
      align-items: center;
      display: flex;
      justify-content: center;
      opacity: .4;

    > img {
        height:30px;
        width: 30px;
      }
    }

    label {
      background-color: var(--color-neon-purple);
      margin: 0;

      > figure {
        margin: 0;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    img {
      object-fit: cover;
    }

    button {
      height: 56px;
      padding: 20px;
      position: absolute;
      right: -20px;
      top: -20px;
      z-index: 2;

      figure {
        height: 22px;
        width: 22px;
        transition: 200ms transform linear;

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      &:hover {
        figure {
          transform: scale(1.08);
        }
      }
    }

    + button {
      margin: 35px auto 20px;
    }
  }
`

const Label = styled.label`
  align-items: center;
  background-color: var(--color-white);
  border-radius: 36px;
  color: var(--color-neon-purple);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  height: 57px;
  justify-content: center;
  line-height: 24px;
  margin: 25px auto 20px;
  padding: 16px 40px;
  width: 100%;

  figure {
    display: flex;
    height: 15px;
    margin-right: 20px;
    width: 15px;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
`

const Div = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin: ${vw(20, 'mobile')} auto;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  ${mq.greaterThan('nexus7')} {
    margin: ${vw(20, 'tablet')} auto;
  }

  ${mq.greaterThan('tablet')} {
    margin: ${vw(20, 'desktop')} auto;
  }

  ${mq.greaterThan('desktop')} {
    margin: 20px auto;
  }
  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 30px;
    justify-content: center;

    > figure {
      animation: loader .5s linear infinite;
      height: ${vw(20, 'mobile')};
      width: ${vw(20, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(20, 'tablet')};
        width: ${vw(20, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(20, 'desktop')};
        width: ${vw(20, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 20px;
        width: 20px;
      }
    }

  }

  @keyframes loader {
    0% {
      rotate: 0deg;
    }
    100% {
      rotate: 360deg;
    }
  }
`

const variantsVertical: Variants = {
  initial: {
    opacity: 0,
    y: 0,
    zIndex:0,
    pointerEvents: 'none',
    transition: {
      duration: 0
    }
  },
  animate: {
    opacity: 1,
    y: 0,
    zIndex:999,
    pointerEvents: 'auto',
    transition: {
      duration: .8,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    y: 0,
    zIndex:0,
    pointerEvents: 'none',
    transition: {
      duration: .3,
      ease: 'easeOut'
    }
  }
}

const variants: Variants = {
  initial: {
    opacity: 0,
    x: 0,
    pointerEvents: 'none',
    transition: {
      duration: 0
    }
  },
  animate: {
    opacity: 1,
    x: 0,
    pointerEvents: 'auto',
    transition: {
      duration: .8,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    x: 0,
    pointerEvents: 'none',
    transition: {
      duration: .3,
      ease: 'easeOut'
    }
  }
}
