'use client'
import { type FC, type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { useRouter, useParams } from 'next/navigation'
import styled from 'styled-components'
import { AnimatePresence, motion, type Variants } from 'framer-motion'
import { useTranslator } from '@/hooks'
import { cookie } from '@/utils'
import { QUERY_TUNES_STATUS, fetchAPI } from '@/api'

type Props = {
  ok: boolean
  setDisplayFeedback: Dispatch<SetStateAction<boolean>>
}

export const Feedback: FC<Props> = ({ ok = true, setDisplayFeedback }) => {
  const router = useRouter()
  const { t } = useTranslator()
  const [processingStatusId, setProcessingStatusId] = useState(null)
  const { lang } = useParams()

  useEffect(() => { 
    if(ok) {  
      const fetchPTunesData = async () => {
        const token = cookie.get('DY_AICAM_USER')
        try {
          const res = await fetchAPI('graphql', null, {
            method: 'POST',
            body: JSON.stringify({ query: QUERY_TUNES_STATUS }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'DY-User-Token': token
            },
          }) as Response
          if (!res.ok) throw new Error('QUERY_TUNES_STATUS failed')
          if (res.status !== 200) return
          const data = await res.json()
          const tunesData = data.data.tunes
          const tunesDataId = tunesData.map((tune) => (tune.status === 'processing' || tune.status === 'created') ? tune.id : null).filter((id) => id !== null)[0]
          if(tunesDataId) setProcessingStatusId(tunesDataId)
        } catch (error) {
          console.error('catch QUERY_TUNES_STATUS error', error)
        }
      }
      fetchPTunesData()
      if(processingStatusId) router.push(`/${lang}/project/${processingStatusId}`)
      else router.push(`/${lang}/projects`)
    }}, [lang, ok, processingStatusId, router])

  return (
    <AnimatePresence>
      {ok ?
        <Div variants={variants} initial='initial' animate='animate' exit='exit'>
          <p>{t('modal.new_project.feedback.title')} </p>
          <p>{t('modal.new_project.feedback.message')}</p>
        </Div>
        :
        <Div variants={variants} initial='initial' animate='animate' exit='exit'>
          <p>{t('page_error.500')} </p>
          <button onClick={() => {
            setDisplayFeedback(false)
            router.push(`/${lang}/`)
          }}>{t('commons.actions.continue')} </button>
        </Div>
      }
    </AnimatePresence>
  )
}

const Div = styled(motion.div)`
  p {
    color: var(--color-neon-purple);
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }

  button,
  a {
    align-items: center;
    background-color: var(--color-blue);
    border-radius: 36px;
    color: var(--color-black);
    display: flex;
    font-size: 18px;
    height: 65px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 40px;
    padding: 16px 40px;
    width: auto;
    width: fit-content;
  }

  p, 
  a, 
  button {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`

const variants: Variants = {
  initial: {
    opacity: 0,
    x: 150,
    pointerEvents: 'none',
    transition: {
      duration: 0
    }
  },
  animate: {
    opacity: 1,
    x: 0,
    pointerEvents: 'auto',
    transition: {
      duration: .8,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    x: -50,
    pointerEvents: 'none',
    transition: {
      duration: .3,
      ease: 'easeOut'
    }
  }
}
