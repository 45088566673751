import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useState, useEffect } from 'react'


type TypeState = {
  clickedIdx: number
  setClickedIdx: Dispatch<SetStateAction<number>>
}

const initialState: TypeState = {
  clickedIdx: 0,
  setClickedIdx: () => { },
}

export const ImagesContext = createContext<TypeState | null>(initialState)

interface Props {
  children: ReactNode
}

export const ImagesProvider: FC<Props> = ({ children }) => {
  const [clickedIdx, setClickedIdx] = useState<number>(0)

  useEffect(() => {
    if(clickedIdx) setClickedIdx(clickedIdx)
    
  }, [clickedIdx])



  return <ImagesContext.Provider value={{ clickedIdx, setClickedIdx }}>{children}</ImagesContext.Provider>
}
