/* eslint-disable quotes */
import { HOSTNAME } from '@/utils'

export const jsonLdSignup = (_data, locale) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Signup Page",
    "description": "Signup to get an account and enjoy personalized features.",
    "url": `https://${HOSTNAME}/${locale}/signup`,
    "potentialAction": {
      "@type": "ViewAction",
      "target": `https://${HOSTNAME}/${locale}/signup`,
      "name": "Signup"
    },
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": `https://${HOSTNAME}/${locale}`
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Signup Page",
          "item": `https://${HOSTNAME}/${locale}/signup`
        }
      ]
    }
  }

  return JSON.stringify(schema)
}
