'use client'
import styled from 'styled-components'
import { mq, vw, getP16, hex2Rgba, font40_40 } from '../styles'

export const Form = styled.form<any>`
  align-items: flex-start;
  display: grid;
  color: var(--color-text);
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  min-width: 90%;

  > fieldset {
    color: var(--color-text);
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    padding: 0 ${vw(8, 'mobile')};
    pointer-events: ${({ $formVisible }) => $formVisible ? 'inherit' : 'none !important'};
    top: 55px;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(8, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(16, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 16px;
    }
  }

  > button {
    flex-grow: 2;
    margin: 0 ${vw(4.25, 'mobile')} ${vw(4.25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin: 0 ${vw(4.5, 'nexus7')} ${vw(4.5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin: 0 ${vw(12.5, 'desktop')} ${vw(12.5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin: 0 12.5px 12.5px;
    }
  }

  > .subtext {
    ${getP16()}
    color: ${({ theme }) => hex2Rgba(theme.colors.grey, .5)};
    margin-top: ${vw(30, 'mobile')};
    text-align: center;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      margin-top: ${vw(30, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(50, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 50px;
    }

    a,
    button {
      ${getP16()}
      color: inherit;
      transition: 300ms color ease-out;
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => hex2Rgba(theme.colors.text, 1)};
      }
    }
  }

  legend {
    ${font40_40(true, 400)}
    color: var(--color-neon-purple);

    ${mq.greaterThan('tablet')} {
      ${font40_40(false, 400)}
    }
  }
`
