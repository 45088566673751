/* eslint-disable quotes */
import { HOSTNAME } from '@/utils'

export const jsonLdHomepage = (_data, locale) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": `https://${HOSTNAME}${locale === 'en' ? '' : `/${locale}`}`,
    "name": "AI-Generated Images Portal",
    "description": "A platform offering high-quality, AI-generated images for registered users, spanning a wide range of categories from digital art to realistic scenes.",
    "publisher": {
      "@type": "Organization",
      "name": "AICam.studio",
      "logo": {
        "@type": "ImageObject",
        "url": `https://${HOSTNAME}/images/svg/logo.svg`
      }
    },
    "potentialAction": {
      "@type": "RegisterAction",
      "name": "Register",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `https://${HOSTNAME}/${locale}/signup`,
      },
      "description": "Sign up to access an unlimited power of AI-generated images tailored to various themes and preferences totally customizable by you."
    },
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "AI-Generated Image Categories",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Realistic Scenes",
            "description": "Hyper-realistic scenes created by artificial intelligence."
          }
        }
        // Additional categories/services can be listed here
      ]
    }
  }

  return JSON.stringify(schema)
}
