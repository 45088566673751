'use client'
import type { FC, Dispatch, SetStateAction } from 'react'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { motion, type Variants } from 'framer-motion'
import type { UseFormRegister, RegisterOptions } from 'react-hook-form'
import { type TypeFormInputs, Button, ButtonBack } from './'
import { useTranslator } from '@/hooks'
import { mq } from '@/styles'

type Props = {
  register: UseFormRegister<TypeFormInputs>
  step: number
  setStep: Dispatch<SetStateAction<number>>
  setRadioValue: Dispatch<SetStateAction<string>>
}
const getGenders = (onChange, data, registerKeys) =>{
  return data.filter(el => el.value !== 'all').map((item, idx) => {
    return (
      <div  key={idx}>
        <Input type='radio' {...registerKeys}  key={idx}  onChange={onChange} id={item.value === 'other' ? 'nongender' : item.value} value={item.value} />
        <Label key={idx-item.value}  htmlFor={item.value === 'other' ? 'nongender' : item.value}>
          <span>{item.name}</span>
        </Label>
      </div>
    )
  })
}

export const InputRadio: FC<Props> = memo(({ register, setStep, step, setRadioValue }) => {
  const requireProps: RegisterOptions = { required: true }
  const registerKeys = register('gender', requireProps)
  const { t } = useTranslator()
  const [nextStepDisabled, setNextStepDisabled] = useState(true)

  const onChange = useCallback(e => {
    setRadioValue(e.target.value)
    setNextStepDisabled(e.target.value === undefined)
    window.scrollTo({ top: 200, behavior: 'smooth' })

  },[setRadioValue])
  
  const Radio = useMemo(() => getGenders(onChange, t('modal.new_project.steps.two.radio'), registerKeys), [onChange, registerKeys, t])

  return (
    <Fieldset key={2} variants={variants} initial='initial' animate={step === 2 ? 'animate' : 'exit'} exit='exit' $step={step}>
      {Radio}
      <div className='actions'>
        <ButtonBack onClick={() => { setStep(1) }} text={t('commons.actions.prev') as string}  iconName='caret-left' />
        <Button disabled={nextStepDisabled} onClick={() => { setStep(3) }} text={t('commons.actions.next') as string} iconName='caret-right' afterString={true} />
      </div>
    </Fieldset>
  )
})

const Fieldset = styled(motion.fieldset)<{ $step: number }>`
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 310px;
  pointer-events: ${({ $step }) => $step === 2 ? 'auto' : 'none' };
  width: 100%;

  ${mq.greaterThan('tablet')} {
    max-width: 480px;
  }

  legend {
    margin-bottom: 35px;
    text-align: center;
  }

  .actions {
    display: flex;
    margin: 100px 0 20px;
    justify-content: space-around;

    button {
      height: 52px;
      margin: 0 2px;
      transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);

      &:last-of-type {
        svg {
          margin-left: 10px;
        }

        &:hover {
          path {
            stroke: #FFF;
          }
        }
      }
    }
  }
`

const Input = styled.input`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;

  &:checked {
    + label {
      background-color: var(--color-white);
      border-color: var(--color-white);
      span {
        color: var(--color-neon-purple)
      }
    }
  }
`

const Label = styled.label`
  align-items: center;
  background-color: var(--color-background);
  border-radius: 36px;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  opacity: 1;
  padding: 16px 40px;
  transition: 200ms background-color ease-in-out, 200ms border-color ease-in-out, 200ms border-width ease-in-out, 200ms opacity ease-in-out, 200ms color ease-in-out;
  
  &:hover {
    background-color: var(--color-white);
    opacity: .9;
    span {
      color: var(--color-neon-purple);
    }
  }
  
  span {
    color: var(--color-white);
    font-size: 18px;
    line-height: 24px;
  }
`

const variants:Variants = {
  initial: {
    opacity: 0,
    x: 0,
    zIndex:0,
    pointerEvents: 'none',
    transition: {
      duration: 0
    }
  },
  animate: {
    opacity: 1,
    x: 0,
    zIndex:999,
    pointerEvents: 'auto',
    transition: {
      duration: .8,
      ease: 'easeOut'
    }
  },
  exit: {
    opacity: 0,
    x: 0,
    zIndex:0,
    pointerEvents: 'none',
    transition: {
      duration: .3,
      ease: 'easeOut'
    }
  }
}
