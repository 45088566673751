'use client'
import { useState, useEffect, useCallback, useRef } from 'react'
import { Form, InputRadio, InputFile, InputName, Feedback } from '..'
import styled from 'styled-components'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { fetchAPI } from '@/api'
import { font20_20, mq } from '@/styles'
import { cookie } from '@/utils'
import type { FC, Dispatch, SetStateAction } from 'react'
import { useSession } from '@/hooks'

export type TypeFormInputs = {
  gender: 'man' | 'woman' | 'other'
  title: string
  input_images?: File[]
  base_tune_id?: any
  model_type ?: 'no_model' | 'lora' | 'pti'
  branch ?: 'no_branch' | 'sd15' | 'sdxl1' | 'fast'
  face_crop ?: true
}

type Props = {
  formVisible: boolean
  setFormVisible: Dispatch<SetStateAction<boolean>>
}

export const FormSteps: FC<Props> = ({ formVisible , setFormVisible }) => {
  const processingMessageRef = useRef(null)
  const [step, setStep] = useState(1)
  const [radioValue, setRadioValue] = useState(null)
  const [displayFeedback, setDisplayFeedback] = useState(false)
  const [responseSuccess, setResponseSuccess] = useState(false)
  const [selfiesFiles, setSelfiesFiles] = useState<File[]>([])
  const [,setDisabled] = useState(false)
  const [processingIdTune, setProcessingIdTune] = useState(null)
  const [previewImagesState, setPreviewImagesState] = useState<boolean>(false)
  const [previewSelfies, setPreviewSelfies] = useState([])
  const [alreadyUploaded, setAlreadyUploaded] = useState(false)
  const [processingImages, setProcessingImages] = useState<number>(1)
  const { register, handleSubmit, resetField } = useForm<TypeFormInputs>()
  const { getUser, setLoaderActive } = useSession()

  const onSubmit: SubmitHandler<TypeFormInputs> = useCallback(async submitData => {
    setDisabled(true)
    setLoaderActive(true)
    let formdata = new FormData()
    formdata.append('tune[gender]', submitData.gender ? submitData.gender : radioValue ? radioValue : 'other')
    formdata.append('tune[title]', submitData.title)

    selfiesFiles.forEach(file => {
      formdata.append('tune[input_images][]', file, file.name)
    })

    const token = cookie.get('DY_AICAM_USER')
    try {
      const response = await fetchAPI('create-tune', null, {
        method:'POST',
        //   'Content-Type': 'multipart/form-data',
        // We left headers empty so browser add automatically the contentType plus some extra boundary data
        headers: {
          'DY-User-Token': token
        },
        body: formdata,
        redirect: 'follow'
      })

      if(response?.ok) {
        const data = await response.json()
        if(data.id) {
          setProcessingIdTune(data.id)
          setPreviewImagesState(true)
          setLoaderActive(false)

          window.scrollTo({
            behavior: 'smooth',
            top:
              document.querySelector('div[id="processing"]').getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              85,
          })
        }

        setPreviewSelfies(data.media)
      }
      // setResponseSuccess(response?.ok)
      // setDisplayFeedback(true)
      setAlreadyUploaded(true)
      getUser(token)
      resetField('input_images')
    } catch(e) {
      console.log('🟡 Error on fetchAPI', e)
      console.warn('catch e ', e)
      setResponseSuccess(false)
      setDisplayFeedback(true)
    }
  }, [getUser, radioValue, resetField, setLoaderActive, selfiesFiles])

  const onUpload: SubmitHandler<TypeFormInputs> = useCallback(async submitData => {
    setDisabled(true)
    setLoaderActive(true)
    let formdata = new FormData()

    formdata.append('tune[gender]', submitData.gender ? submitData.gender : radioValue ? radioValue : 'other')
    formdata.append('tune[title]', submitData.title)

    selfiesFiles.forEach(file => {
      formdata.append('tune[input_images][]', file, file.name)
    })

    const token = cookie.get('DY_AICAM_USER')
    try {
      const response = await fetchAPI('update-tune', { project_id:processingIdTune } ,{
        method: 'PUT',
        //   'Content-Type': 'multipart/form-data',
        // We left headers empty so browser add automatically the contentType plus some extra boundary data
        headers: {
          'DY-User-Token': token
        },
        body: formdata,
        redirect: 'follow'
      })


      if(response?.ok) {
        const data = await response.json()

        if(data.id) {
          setProcessingIdTune(data.id)
          setPreviewImagesState(true)
          setLoaderActive(false)
          setAlreadyUploaded(true)

          window.scrollTo({
            behavior: 'smooth',
            top:
              document.querySelector('div[id="processing"]').getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              85,
          })
        }

        setPreviewSelfies(data.media)
        setProcessingImages((prev) => prev + data.media.length)
      }
      // setResponseSuccess(response?.ok)
      // setDisplayFeedback(true)
      getUser(token)
      resetField('input_images')

    } catch(e) {
      console.log('🟡 Error on fetchAPI', e)
      console.warn('catch e ', e)
      setResponseSuccess(false)
      setDisplayFeedback(true)
      setLoaderActive(false)
    }
  }, [getUser, processingIdTune, radioValue, resetField, selfiesFiles, setLoaderActive])


  const onError = (errors) => {
    console.log('🟡 Errors found before Submit', errors)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [step])

  const onChange = useCallback(() => {
    // if(selfiesFiles.length === 0) return
    if(previewSelfies.length > 0) {
      handleSubmit(onUpload)()

    } else {
      handleSubmit(onSubmit)()
    }

    setSelfiesFiles([])
  }, [handleSubmit, onSubmit, onUpload, previewSelfies.length])


  useEffect(() => {
    if(selfiesFiles.length > 0) onChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfiesFiles])

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit, onError)} encType='multipart/form-data' $formVisible={formVisible}>
      <P>{step} / 3</P>
      {!displayFeedback ? <>
        <InputName register={register} step={step} setStep={setStep} setFormVisible={setFormVisible} formVisible={formVisible} />
        <InputRadio register={register} setStep={setStep} step={step} setRadioValue={setRadioValue}/>
        <InputFile setStep={setStep} setSelfiesFiles={setSelfiesFiles} step={step} processingIdTune={processingIdTune} previewImagesState={previewImagesState} setPreviewImagesState={setPreviewImagesState} previewSelfies={previewSelfies} setPreviewSelfies={setPreviewSelfies} setAlreadyUploaded={setAlreadyUploaded} alreadyUploaded={alreadyUploaded} processingImages={processingImages} setProcessingImages={setProcessingImages} processingMessageRef={processingMessageRef} radioValue={radioValue}/>
      </>
        :
        <Feedback ok={responseSuccess} setDisplayFeedback={setDisplayFeedback}/>
      }
    </Form>
  )
}

const P = styled.p`
  ${font20_20(true, 400)}
  color: var(--color-neon-purple);
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  margin: 0 auto 10px;
  text-align: center;
  width: 100%;

  ${mq.greaterThan('tablet')} {
    ${font20_20(false, 400)}
  }
`
