type RequestCookie = {
  name: string
  value: string
}

type TypeUserCookie = {
  token: string,
  user: {
    name: string
  }
}

export const getUserDataFromCookie = (userCookieValue: string): TypeUserCookie => JSON.parse(userCookieValue)
export const redirectBaseOnUserRole = (userCookie: RequestCookie, redirect, returnHref = false) => {
  if (!userCookie) return null
  // const userData: TypeUserCookie = getUserDataFromCookie(userCookie.value)
  const href = '/'
  if (returnHref) return href
  if (typeof redirect === 'function' && href) {
    redirect(href)
  }
  // PENDING ADD REMOVE USER
}

function shuffleArray(array) {
  let currentIndex = array.length, temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}
export const groupedByCategory = (array, randomize) => {
  const result = array.reduce((acc, obj) => {
    // If there is no category, push to root 'All' category
    if (obj.category === null) {
      acc['All'].examples.push(obj)
      // If category exist, group the incoming examples
    } else if (acc.hasOwnProperty(obj.category.name)) {
      if (!acc[obj.category.name].examples.some(example => example.id === obj.id)) {
        acc[obj.category.name].examples.push(obj)
      }
    } else {
      // If the category name doesn't exist, create a new entry
      acc[obj.category.name] = {
        category: obj.category.name,
        emoji: obj.category.emoji,
        examples: [obj],
      }
    }

    return acc
  }, {
    All: {
      category: 'All',
      examples: array
    }
  })

  for (let key in result) {
    result[key].examples = result[key].examples.reduce((acc, obj) => {
      if (!acc.some(example => example.id === obj.id)) {
        acc.push(obj)
      }
      return randomize ? shuffleArray(acc) : acc
    }, [])
  }

  return result
}


export const transformMedia = (data) => {
  const media = {
    alt: data.alt,
    m: {
      size: { width: data.width, height: data.height },
      src: data.url ?? data.downloadUrl
    }
  }
  return media
}
