/* eslint-disable quotes */
import { HOSTNAME } from '@/utils'
import type { TypeCMSExample } from '@/schema'

type TypeGallery = TypeCMSExample[]

interface ImageObject {
  id: number
  subjectType: {
    name: string
  }
  category: {
    name: string
  }
  [key: string]: any
}

interface Category {
  name: string
}

interface SubjectType {
  "@type": "Thing",
  name: string
  "additionalType": "Category",
  description?: string
  potentialAction: {
    "@type": "SearchAction",
    target: string
  }
  hasPart: Category[]
}

function categorizeSubjects(examples: ImageObject[], locale: string): SubjectType[] {
  const subjectMap = new Map<string, Set<string>>()

  examples.forEach(({ subjectType, category }) => {
    if (!subjectMap.has(subjectType.name)) {
      subjectMap.set(subjectType.name, new Set<string>())
    }

    if (category?.name) subjectMap.get(subjectType.name)?.add(category.name)
  })

  const result: SubjectType[] = []

  subjectMap.forEach((categories, subjectName) => {
    const hasPart: Category[] = Array.from(categories).map(name => ({ name }))
    result.push({
      "@type": "Thing",
      name: subjectName,
      "additionalType": "Category",
      // description: '',
      "potentialAction": {
        "@type": "SearchAction",
        target: `http://aicam.studio/${locale}/gallery/${subjectName.toLowerCase()}`
      },
      hasPart,
    })
  })

  return result
}

// "potentialAction": {
//   "@type": "SearchAction",
//     "target": "http://www.example.com/search?category={category}",
//       "query-input": "required name=category"
// },

export const jsonLdGallery = (data: TypeGallery, locale) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "AICam.studio",
    "url": `https://${HOSTNAME}`,
    "about": {
      "@type": "CreativeWork",
      "description": "Studio | Gallery"
    },
    "hasPart": [
      {
        "@type": "CollectionPage",
        "name": "AI-Generated Portrait Categories",
        "description": "Explore our AI-generated portraits, filtered by categories such as sport, adventure, and street.",
        "url": `https://${HOSTNAME}/${locale}/gallery`,
        "isPartOf": `https://${HOSTNAME}/${locale}/gallery`,
        "about": categorizeSubjects(data, locale),
        "hasPart": data.map(item => (
          {
            "@type": "ImageObject",
            "identifier": item.id,
            "name": item.code,
            "description": item.textPrompt,
            "url": item.image.m.src,
            "contentUrl": item.image.m.src,
            "encodingFormat": "image/webp",
            "about": {
              "@type": "Thing",
              "name": item.subjectType.name,
              ...item.category && { "additionalType": item.category.name }
            }
          }
        ))
      }
    ]
  }

  return JSON.stringify(schema)
}
