'use client'
import { type FC } from 'react'
import styled from 'styled-components'
import { Figure } from './Figure'
import { iconByName, type TypeAppIcon } from '@/utils'

type Props = {
  text: string,
  iconName?: TypeAppIcon
  onClick?: () => void
  type?: 'button' | 'submit',
  disabled?: boolean,
  afterString?: boolean
}

export const Button: FC<Props> = ({ type = 'button', onClick = () => {}, text, iconName, disabled = false, afterString }) => {
  return (
    <Btn type={type} onClick={onClick} disabled={disabled}>
      {iconName && !afterString && <Figure media={iconByName(iconName)} />}
      {text}
      {iconName && afterString && 
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M1 6H12" stroke="#6F4EFB"strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 1.5L12 6L7.5 10.5" stroke="#6F4EFB"strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg> 
      }
    </Btn>
  )
}

const Btn = styled.button`
  align-items: center;
  background-color: var(--color-white);
  border-radius: 36px;
  color: var(--color-neon-purple);
  display: flex;
  font-size: 15px;
  height: 65px;
  justify-content: center;
  line-height: 20px;
  padding: 16px 40px 18px;
  margin-bottom: 0;
  transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  width: auto;
  width: fit-content;

  &:disabled {
    opacity: .6;
    pointer-events: none;
    user-select: none;
  }

  &:before {
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    line-height: 24px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
  }

  &:after {
    top: 0;
    left: 0;
    width: 100%;
    line-height: 24px;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
  }


  &:hover {
    color: var(--color-white);
    background-color: var(--color-neon-purple);
    transform: scale3d(1.1,1.2,1);

    &:before {
      transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
      transform: translate3d(-50%,-50%,0) scale3d(1,1,1);
      line-height: 24px;
    }

    &:after {
      opacity: 1;
      transition-duration: 0.01s;
      transition-delay: 0.3s;
      line-height: 24px;

    }
  }

  figure {
    display: flex;
    height: 15px;
    width: 15px;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
`
