'use client'
import { memo, type FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import type { TypeMedia, TypeVideoExternal } from '@/schema'

type TypeFigure = {
  readonly media: TypeMedia | TypeVideoExternal,
  readonly lazy?: boolean,
  readonly initialInView?: boolean,
  onClick?: any,
  readonly fill?: boolean,
  readonly outRef?: (node?: Element) => void,
  readonly className?: string,
  readonly sizes?: string,
  readonly quality?: number
  readonly ariaHidden?: boolean
  readonly unoptimized?: boolean
}

const FigureStyled = styled.figure`
  display: block;
  position: relative;
  img {
    object-fit: cover;
  }
`

export const Figure: FC<TypeFigure> = memo(({ media, lazy = true, fill = true, onClick = null, className = '', outRef = null, sizes = '70vw', quality = 75, ariaHidden = false, unoptimized = true}) => {
  // const { isMobile } = useDeviceType()
  if (!media) return <figure className='placeholder' />
  if (media.type === 'video') return <></>
  // if(media.type === 'video') return <Video data={media} />
  return (
    <FigureStyled className={className} {...onClick && { onClick }}  {...outRef && { ref: outRef }}>
      {media.type === 'svg' ?
        <Image src={media.src} alt={media.alt} fill={fill} aria-hidden={ariaHidden}
          {...!lazy && { priority: true }}
          {...!fill && { height: media.size.height }}
          {...!fill && { width: media.size.width }}
          unoptimized={true}
        />
        :
        <Image
          // placeholder='blur'
          src={media?.[( media?.s) ? 's' : 'm']?.src} alt={media?.alt} fill={fill}
          {...!lazy && { priority: true }}
          {...!fill && { height: media?.[( media?.s) ? 's' : 'm'].size.height }}
          {...!fill && { width: media?.[( media?.s) ? 's' : 'm'].size.width }}
          sizes={sizes}
          {...quality !== 75 && { quality }}
          {...unoptimized && { unoptimized: true }}
        />
      }
    </FigureStyled>
  )
})
