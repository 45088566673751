import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useState, useEffect } from 'react'
import type { TypeCMSUser } from '@/schema'
import { cookie } from '@/utils'
import { usePathname } from 'next/navigation'


type TypeUser = TypeCMSUser & { token: string }
type TypeState = {
  user: TypeUser
  setUser: Dispatch<SetStateAction<TypeUser>>
  loaderActive: boolean
  setLoaderActive: Dispatch<SetStateAction<boolean>>
  getUser: Dispatch<SetStateAction<any>>
}

const initialState: TypeState = {
  user: null,
  setUser: () => { },
  loaderActive: false,
  setLoaderActive: () => { },
  getUser: () => {}
}

export const SessionContext = createContext<TypeState | null>(initialState)

interface Props {
  children: ReactNode
}

export const SessionProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<TypeUser>(undefined)
  const [loaderActive, setLoaderActive] = useState<boolean>(false)
  const pathname = usePathname()

  const getUser = async (token) => {
    try {
      const res = await fetch('/api/auth/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token })
      })

      if (res.status === 200) {
        const data = await res.json()
        if (data?.data?.user !== null && typeof data?.data?.user === 'object') {
          setUser({ ...data.data.user, token })
        }
      }
    } catch (e) {
      console.warn('SessionContext USER query - Catch e', e)
      // PENDING MAYBE REMOVE COOKIE AND REDIRECT
    }
  }

  useEffect(() => {
    const token = cookie.get('DY_AICAM_USER')
    if (!token || user) return
    getUser(token)

  }, [pathname, user])


  return <SessionContext.Provider value={{ user, setUser, loaderActive, setLoaderActive, getUser }}>{children}</SessionContext.Provider>
}
