import { FC, ReactNode, Dispatch, SetStateAction, useEffect } from 'react'
import { createContext, useState } from 'react'


type TypeState = {
  numberOfProcessingImages: number
  setNumberOfProcessingImages : Dispatch<SetStateAction<number>>
}

const initialState: TypeState = {
  numberOfProcessingImages: 0,
  setNumberOfProcessingImages: () => { },
}

export const NumberOfProcessingImagesContext = createContext<TypeState | null>(initialState)

interface Props {
  children: ReactNode
}

export const NumberOfProcessingImagesProvider: FC<Props> = ({ children }) => {
  const [numberOfProcessingImages, setNumberOfProcessingImages] =  useState<number>(null)

  useEffect(() => {
    if(numberOfProcessingImages) setNumberOfProcessingImages(numberOfProcessingImages)

  }, [numberOfProcessingImages])


  return <NumberOfProcessingImagesContext.Provider value={{ numberOfProcessingImages, setNumberOfProcessingImages }}>{children}</NumberOfProcessingImagesContext.Provider>
}
