export const MUTATION_SIGN_UP = ({ name, email, password, lang }) => `
  mutation {
    userRegister(
      input: {
        user: {
          name: "${name}"
          credentials: { email: "${email}", password: "${password}" }
          lang: "${lang}"
        }
      }
    ) {
      ok
      token
      expiryDate
      user {
        id
        name
        email
        credits
        lang
        templatesCount
      }
    }
  }
`

export const MUTATION_LOG_IN = ({ email, password, lang }) => `
  mutation {
    userLogin(
      input: {
        credentials: {
          email: "${email}"
          password: "${password}"
        }
        lang: "${lang}"
      }
    ) {
      ok
      token
      user {
        id
        name
        email
        credits
        lang
        templatesCount
      }
    }
  }
`

export const MUTATION_FORGOT_PASSWORD = ({ email, lang }) => `
  mutation {
    userEmailResetPassword(input: { email: "${email}", lang: "${lang}" }) {
      ok
    }
  }
`

export const MUTATION_RESET_PASSWORD = ({ password }) => `
  mutation {
    userResetPassword(input: { password: "${password}" }) {
      ok
      user {
        name
      }
    }
  }
`

export const QUERY_USER = `
  query {
    user {
      id
      name
      email
      credits
      lang
      templatesCount
    }
  }
`
export const QUERY_PROMPTS = `
  query {
    id
    title
  }
`

export const QUERY_ALBUM =(id) => `
  query {
    album(id: ${id}) {
      tuneId
      textPrompt
      title
      createdAt
      updatedAt
      prompts {
        negativePrompt
        textPrompt
      }
      tune {
        title
        gender
      }
      promptImages {
        albumId
        id
        image(variants: ["xl"])
        originalUrl
        promptId
      }
    }
  }
`

export const QUERY_TUNES_GRID = `
  query {
    tunes {
      id
      image
      status
      title
      user {
        name
        templatesCount
      }
    }
  }
`

export const QUERY_TUNES_STATUS = `
  query {
    tunes {
      id
      previousStatus
      status
    }
  }
`

export const QUERY_TUNE = (id, status) => `
  query {
    tune(id: ${id}) {
      id
      title
      gender
      status
      tuneType
      createdAt
      expiresAt
      albums {
        id
        promptImages {
          albumId
          id
          promptId
          image(variants: ["m"])
        }
        prompts {
          title
          textPrompt
          negativePrompt
          status
          id
          promptImages {
            albumId
            id
            promptId
            image(variants: ["m"])
          }
        }
        processing:prompts(status: ${status}) {
          id
          numberOfImages
        }
      }
    }
  }
`

export const QUERY_TUNE_STATUS = (id) => `
  query {
    tune(id: ${id}) {
      id
      title
      status
    }
  }
`

export const QUERY_TUNE_PREVIEW_IMAGES = (id) => `
  query {
    tune(id: ${id}) {
      id
      title
      gender
      status
      media {
        id
        image
        status
      }
    }
  }
`

export const QUERY_ALBUM_STATUS = (id, status) => `
  query {
    album(id: ${id}) {
      tuneId
      textPrompt
      title
      createdAt
      textPrompt
      promptImages {
        albumId
        id
        promptId
        originalUrl
        image
      }
      processing:prompts(status: ${status}) {
        id
        numberOfImages
      }
    }
  }
`

// export const QUERY_EXAMPLES_BY_SEARCH =({ subjecType }) => `
//   query {
//     examples(subjectType: "${subjecType}") {
//       id
//       code
//       subjectType {
//         name
//       }
//       category {
//         name
//         emoji
//       }
//       textPrompt
//       negativePrompt
//       image
//     }
//   }
// `

export const QUERY_EXAMPLES_BY_PROJECT_ID = (projectId: number) => `
  query {
    examples(genderTuneId: ${projectId}) {
      id
      code
      subjectType {
        value:code
        name
      }
      category {
        value:code
        name
        emoji
      }
      textPrompt
      negativePrompt
      image(webp: false)
    }
  }
`

export const QUERY_EXAMPLES = `
  query {
    examples {
      id
      code
      subjectType {
        value:code
        name
      }
      category {
        value:code
        name
        emoji
      }
      textPrompt
      negativePrompt
      image(webp: false)
    }
  }
`

export const MUTATION_CREATE_ALBUM = ({ textPrompt, negativePrompt, numberOfImages, tuneId, title, titleAlbum }) => `
  mutation {
    albumCreate(
      input: {
        tuneId:${tuneId}
        title: "${titleAlbum}"
        prompt: {
          title: "${title}"
          textPrompt: "${textPrompt}"
          negativePrompt: "${negativePrompt}"
          numberOfImages: ${numberOfImages}
        }
      }
    ) {
      ok
      album {
        id
        tuneId
        tune {
          user {
            credits
          }
        }
        textPrompt
        title
        createdAt
      }
      prompts {
        id
        negativePrompt
        numberOfImages
        status
        textPrompt
        title
        tuneId
      }
    }
  }
`

export const MUTATION_UPDATE_ALBUM=({ albumId, title, titlePrompt, numberOfImages }) => `
  mutation {
    albumUpdate(
      input: {
        albumId: ${albumId}
        title:  "${title}"
        prompt: {
          title:  "${titlePrompt}"
          numberOfImages: ${numberOfImages}
        }
      }
    ) {
      ok
      album {
        id
        tuneId
        tune {
          user {
            credits
          }
        }
        textPrompt
        title
        createdAt
      }
      prompts {
        id
        tuneId
        title
        numberOfImages
        status
        previousStatus
        textPrompt
        negativePrompt
      }
    }
  }
`


export const MUTATION_DELETE_ALBUM = ({ id }) => `
  mutation {
    albumDelete(
      input: {
        id: ${id}
      }
    ) {
      ok
      album {
        id
      }
    }
  }
`


export const MUTATION_DELETE_TUNE = ({ id }) => `
  mutation {
    tuneDelete(
      input: {
        id: ${id}
        force: true
      }
    ) {
      ok
      tune {
        id
        title
        status
      }
    }
  }
`

export const MUTATION_DELETE_USER = `
  mutation {
    userDelete(input: {}) {
      ok
      user {
        createdAt
        credits
        email
        failedAttempts
        id
        lang
        lockedAt
        name
        passwordDigest
        signInAt
        signInCount
        status
        updatedAt
      }
    }
  }
`

export const MUTATION_UPDATE_TUNE_TITLE = ({ id, title }) => `
  mutation {
    tuneUpdate(
      input: {
        id: ${id},
        tune: {
          title: "${title}"
        }
      }
    ) {
      ok
      tune {
        astriaId
        branch
        createdAt
        faceCrop
        gender
        id
        modelType
        status
        title
        token
        updatedAt
        userId
      }
    }
  }
`

export const MUTATION_DELETE_ALBUM_IMAGE = ({ promptImageId }) => `
  mutation {
    promptImageDelete(input: { id: ${promptImageId} }) {
      ok
      album {
        id
        textPrompt
        title
        tuneId
        updatedAt
      }
    }
  }
`

export const QUERY_GET_ORDER = ({id}) => `
  query {
    order(id: ${id}) {
      id
      paymentStatus
      credits
    }
  }
`
