import { css } from 'styled-components'
import { mq } from '.'

const rem = {
  baseMobile: 15,
  base: 18,
  get: (value:number, factor = null) => `${value / (factor ?? rem.baseMobile)}rem`
}

// font: ABCFAVORIT
export const getP20 = () => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(22)};
  font-weight: 600;
  line-height: ${rem.get(20)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(22, rem.base)};
    line-height: ${rem.get(20, rem.base)};
  }
`
export const getP35 = () => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(35)};
  font-weight: 400;
  line-height: ${rem.get(40)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(35, rem.base)};
    line-height: ${rem.get(40, rem.base)};
  }
`
export const getP40 = (weight:400|'bold' = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(40)};
  font-weight: ${weight};
  line-height: ${rem.get(35)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(55, rem.base)};
    line-height: ${rem.get(52, rem.base)};
  }
`
export const getP55 = () => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(55)};
  font-weight: bold;
  line-height: ${rem.get(52)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(55, rem.base)};
    line-height: ${rem.get(52, rem.base)};
  }
`

export const getP60 = () => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(60)};
  font-weight: 400;
  line-height: ${rem.get(65)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(60, rem.base)};
    line-height: ${rem.get(65, rem.base)};
  }
`

export const getP160 = () => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(65)};
  font-weight: 400;
  line-height: ${rem.get(70)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(160, rem.base)};
    line-height: ${rem.get(170, rem.base)};
  }
`

// font: FKScreamer
export const getP13 = (weight: 400|600 = 400) => css`
  font-size: ${rem.get(13)};
  font-weight: ${weight};
  line-height: ${rem.get(15)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(13, rem.base)};
    line-height: ${rem.get(15, rem.base)};
  }
`

export const getP13_2 = (weight: 400|600 = 400) => css`
  font-size: ${rem.get(13)};
  font-weight: ${weight};
  line-height: ${rem.get(15)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(18, rem.base)};
    line-height: ${rem.get(20, rem.base)};
  }
`

export const getP16 = (weight: 400|600 = 400) => css`
  font-size: ${rem.get(16)};
  font-weight: ${weight};
  line-height: ${rem.get(16)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(16, rem.base)};
    line-height: ${rem.get(16, rem.base)};
  }
`

export const getP18 = (weight: 400|600 = 400) => css`
  font-size: ${rem.get(18)};
  font-weight: ${weight};
  line-height: ${rem.get(20)};
  font-family: var(--font-satoshi);

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(18, rem.base)};
    line-height: ${rem.get(20, rem.base)};
  }
`

export const getP22 = (weight: 400|600 = 400) => css`
  font-size: ${rem.get(22)};
  font-weight: ${weight};
  line-height: ${rem.get(22)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(22, rem.base)};
    line-height: ${rem.get(22, rem.base)};
  }
`

export const getP40_2 = () => css`
  font-size: ${rem.get(40)};
  font-weight: 600;
  line-height: ${rem.get(41)};

  ${mq.greaterThan('tablet')} {
    font-size: ${rem.get(40, rem.base)};
    line-height: ${rem.get(41, rem.base)};
  }
`


export const font80_84 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(80, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(84, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font65_65 = (mobile: boolean = true) => css`
  font-size: ${rem.get(65, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: 400;
  line-height: ${rem.get(65, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font60_65 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(60, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(65, rem[mobile ? 'baseMobile' : 'base'])};
`
export const font50_52 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(50, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(52, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font50_48 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(50, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(48, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font40_44 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(40, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(44, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font40_40 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(40, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(40, rem[mobile ? 'baseMobile' : 'base'])};
`


export const font35_40 = (mobile: boolean = true) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(35, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: 400;
  line-height: ${rem.get(40, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font30_32 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(30, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(32, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font22_22 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font22_20 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font20_20 = (mobile: boolean = true, weight: 400 | 600 | 'bold' = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(20, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(20, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font18_18 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font18_25 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(25, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font18_22 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-family: var(--font-satoshi);
  font-size: ${rem.get(18, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font16_16 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(16, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(16, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font15_20 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(20, rem[mobile ? 'baseMobile' : 'base'])};
`
export const font15_15 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font15_19 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(19, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font15_22 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font14_14 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(14, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(14, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font14_22 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(14, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(22, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font13_13 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(13, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(15, rem[mobile ? 'baseMobile' : 'base'])};
`

export const font12_12 = (mobile: boolean = true, weight: 400 | 600 = 400) => css`
  font-size: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
  font-weight: ${weight};
  line-height: ${rem.get(12, rem[mobile ? 'baseMobile' : 'base'])};
`


// PENDING MOVE TO THE NEW FORMAT
export const getP18InputLabel = () => {
  return css`
    font-size: ${rem.get(15)};
    font-weight: 500;
    line-height: ${rem.get(15)};
    letter-spacing: -.3px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(18, rem.base)};
      line-height: ${rem.get(15, rem.base)};
      letter-spacing: -.36px;
    }
  `
}

export const getP18InputHasContentLabel = () => {
  return css`
    font-size: ${rem.get(12)};
    font-weight: 500;
    line-height: ${rem.get(10)};
    letter-spacing: -.3px;

    ${mq.greaterThan('tablet')} {
      font-size: ${rem.get(12, rem.base)};
      line-height: ${rem.get(10, rem.base)};
      letter-spacing: -.36px;
    }
  `
}
